import React from 'react'
import {Deutsch, English, Type, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/powernewz/1.jpg"
import img2 from "../../images/powernewz/2.jpg"
import img3 from "../../images/powernewz/3.jpg"
import img4 from "../../images/powernewz/4.jpg"
import img5 from "../../images/powernewz/5.jpg"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    powernewz is a blog focused on energy and sustainability topics.
                    <p/>
                    My role is to search topics and write an in-depth energy story.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>


              </Type>
        </English>



        <Deutsch Language={location.search}>
              <Type>
                    powernewz ist ein Blog, der auf Energie- und Nachhaltigkeitsthemen fokussiert.
                    <p/>
                    Meine Rolle ist, neue Themen zu suchen und tiefgehende Artikel zu schreiben.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </Deutsch>


    </div>

<Element name="test1"></Element>

    <img src={img1}/>





      <div className="comment">
        <English Language={location.search}>
              <Type>
              The blog writes about energy topics, sustainability and smart technoglogies to reinforce the strategic position of ewz as an environmentally engaged innovator.
              </Type>
        </English>


        <Deutsch Language={location.search}>
              <Type>
                    
                The blog schreibt über Energiethemen, Nachhaltigkeit und Smarte Technologien um die strategische Position von ewz als umweltungegagierte Innovationsfirma zu stärken.

              </Type>
        </Deutsch>

      </div>


      <img src={img2}/>







      <div className="comment">

        <English Language={location.search}>
              <Type>

              New topics and articles must pass multiple layers of internal stakeholders and satisfy their interests before being published.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                Neue Themen und Artikel müssen mehrere Schichten interner Stakeholders durchdringen und deren Interessen befriedigen bevor sie publiziert werden können.

              </Type>
        </Deutsch>

      </div>

      <img src={img3}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Each post requires substantial research and reading to reach the required level of technical accuracy.

              </Type>
        </English>


        <Deutsch Language={location.search}>
              <Type>
                    
                Jeder Artikel verlangt eingehende Recherche oder Interviews.

              </Type>
        </Deutsch>

      </div>


      <img src={img4}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Technologies make interesting stories - they make our civilization go forward after all, towards enlightenment or a man-made apocalypse.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                Technologien bieten interessante Geschichten. Sie lassen unsere Zivilisation vorwärts gehen, oder bereiten ihre Apokalypse vor.

              </Type>
        </Deutsch>

      </div>


      <img src={img5}/>



      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
